@import '../variables/variables';

.diff-content {
    ins {
        text-decoration: none;
        background-color: #d4fcbc;
        white-space: break-spaces;
    }

    del {
        text-decoration: line-through;
        background-color: #fbb6c2;
        color: #555;
        white-space: break-spaces;
    }

    .hidden-content {
        font-size: 0.8em;
        letter-spacing: 2px;
        color: $gray;
        margin: 14px 0;
    }
}
