.dropzone {
    border: none;
    background: none;
    padding: 20px 0;

    .dz-preview {
        margin: 16px 16px 16px 0;

        .dz-image {
            border-radius: 5px;
        }

        &.dz-file-preview {
            .dz-image {
                border-radius: 5px;
            }
        }
    }
}

.dz-details {
    color: rgb(0 219 201 / 90%);
}

.dz-filename {
    &:hover {
        span {
            border: 1px solid rgb(0 219 201 / 80%);
            background-color: rgb(0 219 201 / 80%);
        }
    }
}
