@use '@angular/material' as mat;

// always include only once per project
@include mat.core;

// import our custom theme
@import 'material/theme';

html {
    font-size: 13px;
}

* {
    img {
        display: block;
    }

    &:focus {
        outline: none;
    }
}

body {
    background-color: $gray-background;
    color: $primary-dark-grey;
    font-size: 14px;
    margin: 0;
    font-family: $font-work-poppins;

    &.white {
        background-color: #fff;
    }

    .sidebar-subtitle {
        line-height: 30px;
        color: $primary-dark-grey;
    }
}

@import 'global/container';
@import 'global/forms';
@import 'global/edit-element';
@import 'global/module-title';
@import 'global/ngx-contextmenu';
@import 'global/dropzone';
@import 'global/dropdown';
@import 'global/cropper';

@import 'editor/document';
@import 'editor/hands_on_table';
@import 'editor/tinymce';
@import 'editor/tree-component';
@import 'editor/tiptap';
@import 'editor/comment-list';
@import 'editor/versioning';
