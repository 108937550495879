@use '@angular/material' as mat;
@import 'palettes';

$primary: mat.get-color-from-palette($elias-app-primary, 500);
$danger: mat.get-color-from-palette($elias-app-warn, 500);
$accent: mat.get-color-from-palette($elias-app-accent, 500);
$warn: mat.get-color-from-palette($lst-yellow, 500);
$info: mat.get-color-from-palette($lst-blue, 500);
$primary-green: #00dbc9;
$primary-dark-grey: #334756;
$gray-background: #f5f5f5;
$gray: #989898;
$gray-light: #c1c1c1;
$purple: #8526ff;
$border-radius: 23.5px;
$background-color-fill: rgb(216 216 216 / 5%);
$light-background: #fdfdfd;
$font-work-poppins: 'Poppins', sans-serif;
$font-work-sans: 'Work Sans', sans-serif;
$font-merriweather: 'Merriweather', serif;
$font-title: $font-work-poppins;

// Shadows
$box-shadow-strong: 0 10px 10px 10px rgb(51 71 86 / 9%), 0 3px 14px 10px rgb(51 71 86 / 7%);
$box-shadow-medium: 0 10px 10px 0 rgb(51 71 86 / 7%), 0 3px 14px 0 rgb(51 71 86 / 6%);
$box-shadow-light: 0 10px 10px 0 rgb(51 71 86 / 5%), 0 3px 14px 0 rgb(51 71 86 / 4%);
$box-shadow: $box-shadow-medium;

// Header
$header-height: 0;

// Toolbar
$toolbar-height: 93px;

// Sidebars
$sidebar-collapsed-width: 120px;
$sidebar-opened-width: 320px;
$sidebar-right-opened-width: 270px;
$sidebar-left-opened-width: 370px;

// Transition
$base-transition: 0.25s all ease-in-out;
