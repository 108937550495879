@use '@angular/material' as mat;

div.cdk-overlay-container {
    z-index: 1051;
}

.dropdown-menu {
    position: fixed;
    margin: 0;
    padding: 0;
    background: white;
    list-style: none;
    z-index: 1052;
    box-shadow: $box-shadow;

    > li {
        a {
            display: block;
            padding: 3px 10px;

            &:hover {
                background: #eee;
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    li.disabled {
        a {
            color: #ddd;
        }
    }

    li.divider {
        border-bottom: 1px solid #eee;
        margin: 2px 0;
    }
}

.ngx-contextmenu {
    .icon {
        display: inline-block;
        margin-right: 10px;
    }

    .dropdown-menu {
        padding: 6px 0;

        > li {
            > a {
                color: $primary;
                font-size: 15px;
                padding: 5px 10px;
                text-decoration: none;

                &:hover {
                    transition: all 0.3s ease;
                    color: mat.get-color-from-palette($elias-app-primary, 800);
                }
            }
        }
    }
}
