@use 'sass:color';

.pe-element-inner {
    position: relative;

    .element-button {
        border-radius: 1.25em;
        padding: 6px 10px;
        border: 1px rgb(0 0 0 / 10%) dashed;
        background: #fafafa;
        bottom: -22px;
        position: absolute;
        left: 90%;

        &:hover {
            background: color.adjust(black, $lightness: 80%);
        }
    }

    button.btn-link.remove {
        font-size: 14px;
    }
}

.form-document {
    .form-group {
        padding-bottom: 5px;

        input {
            padding: 6px 10px;
        }
    }
}

.pe-element-button-container {
    margin-top: 1rem;
}
