tree-viewport {
    overflow: hidden;
    height: auto;
    margin-left: -15px;
}

.angular-tree-component {
    .tree-node {
        border: 1px solid transparent;

        .node-wrapper {
            font-size: 18px;
            font-weight: 500;
            transition: all 0.2s ease-in-out;

            .node-content-wrapper {
                transition: all 0.2s ease-in-out;
                padding: 10px 8px;
                width: 100%;
                display: block;
            }

            .toggle-children-wrapper {
                display: inline-block;
                line-height: 30px;
                padding: 0 10px;
                width: 15px;

                .toggle-children {
                    position: relative;
                    background: none;
                    height: 18px;
                    width: 15px;

                    &::before {
                        font-family: 'Material Icons';
                        font-size: 22px;
                        font-weight: normal;
                        content: 'keyboard_arrow_up';
                        display: block;
                        position: absolute;
                        color: white;
                    }
                }
            }

            .toggle-children-wrapper-expanded {
                .toggle-children {
                    transform: none;

                    &::before {
                        content: 'keyboard_arrow_up';
                    }
                }
            }

            .toggle-children-wrapper-collapsed {
                .toggle-children {
                    transform: none;

                    &::before {
                        content: 'keyboard_arrow_down';
                    }
                }
            }

            .toggle-children-placeholder {
                height: 18px;
                width: 15px;
                padding: 0 10px;
            }

            &:hover {
                .node-content-wrapper {
                    background: transparent;
                    box-shadow: none;

                    i {
                        opacity: 1;
                    }
                }
            }
        }

        .tree-children {
            .node-wrapper {
                font-size: 15px;
            }
        }

        .tree-node-level-1 {
            .node-wrapper {
                font-weight: bold;
            }

            .tree-children {
                padding: 5px 0;
            }
        }

        .tree-node-level-2 {
            .node-wrapper {
                padding-left: 25px;
                font-weight: normal;
            }
        }

        .tree-node-level-3 {
            .node-wrapper {
                padding-left: 25px;
                font-weight: normal;
            }
        }

        .is-dragging-over {
            background: rgba(#4dbdb8, 0.1);
        }
    }

    .node-value > span {
        color: white;
    }

    tree-node-wrapper {
        display: block;
        border: 1px solid transparent;
        margin-bottom: 5px;

        &:hover {
            border: 1px solid white;
            border-radius: 4px;
        }
    }

    .tree-node-active > tree-node-wrapper,
    .tree-node-focused > tree-node-wrapper {
        background: rgb(255 255 255 / 10%);
        border-radius: 4px;
        border: 1px solid white;
    }

    .node-content-wrapper-active,
    .node-content-wrapper-focused {
        background: transparent;
        box-shadow: none;
    }
}

elias-editor {
    .angular-tree-component .tree-node .node-wrapper .toggle-children-wrapper .toggle-children {
        height: 14px;
    }
}
