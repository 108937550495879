@import '../variables/variables';
@import 'cropperjs/dist/cropper.css';

.cropper-point,
.cropper-line {
    background-color: $primary-green;
}

.cropper-view-box {
    outline-color: $primary-green;
}
