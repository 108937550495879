elias-shared-module-title,
elias-shared-module-title-options,
elias-publication-overview-title-with-language-options {
    .mat-raised-button,
    .mat-stroked-button,
    .mat-flat-button,
    .mat-button {
        padding: 6px 28px;

        mat-spinner {
            display: inline-block;
        }
    }
}
