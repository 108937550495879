@import '../variables/variables';

/* stylelint-disable selector-class-pattern */
.ProseMirror {
    p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
    }

    .mention {
        color: $purple;
    }
}
