@import '../variables/variables';

/**
 * Content is dynamically injected into comment component and therefore requires global styles
 */
.comment-list {
    .comment {
        .content {
            .mention {
                color: $purple;
            }

            p {
                margin: 15px 0 0;
            }
        }
    }
}
