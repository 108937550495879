@import '../variables/variables';

.dropdown-container {
    .mat-form-field-infix {
        width: auto;
    }

    .mat-select-value {
        max-width: initial;
    }

    .mat-form-field-label-wrapper {
        position: relative;
        top: -1.1em;
    }

    .mat-form-field-label {
        position: initial;
        overflow: initial;
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        height: 0;
        width: 0;
    }

    .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding: 0;
    }

    .mat-select-arrow {
        visibility: hidden;
    }

    .mat-select-arrow-wrapper {
        display: none;
    }

    .mat-primary .mat-pseudo-checkbox-checked,
    .mat-primary .mat-pseudo-checkbox-indeterminate {
        background: $primary-green;
    }
}
