mat-form-field {
    &.no-border {
        .mat-form-field-outline-start,
        .mat-form-field-outline-gap,
        .mat-form-field-outline-end {
            border: none;
            background: $gray-light;
        }
    }

    &.mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0.8em 0 1em;
    }

    &.mat-focused {
        &.mat-form-field-type-mat-input {
            &.mat-form-field-appearance-outline .mat-form-field-outline {
                color: $primary-green;
            }

            mat-label {
                color: $primary-green;
            }

            &.mat-form-field-invalid {
                mat-label {
                    color: $danger;
                }

                &.mat-form-field-appearance-outline .mat-form-field-outline {
                    color: $danger;
                }
            }
        }
    }
}

elias-form {
    mat-form-field {
        width: 100%;

        .mat-input-wrapper {
            width: 100%;
        }
    }

    mat-checkbox {
        display: block;
        padding: 12px 0 1.3438em;
    }
}

elias-form-element-color {
    .color-select {
        .mat-select-value-text {
            margin-left: 45px;
        }
    }
}

.mat-checkbox.cdk-program-focused .mat-checkbox-persistent-ripple {
    opacity: 0.12;
}
