@import '../variables/variables';

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;

    /*  padding-left: 15px;
  padding-right: 15px; */
    position: relative;
    width: 100%;
    font-family: $font-work-poppins;
}

.loading-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    mat-spinner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        &.mat-progress-spinner circle {
            stroke: #c2c8cc;
        }
    }
}
