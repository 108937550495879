@import '../variables/variables';

.document {
    img {
        max-width: 100%;
    }

    .mce-edit-focus {
        outline: 0 !important;
        border: none;
    }

    .table {
        width: 100%;

        td {
            padding: 0.75rem;
        }
    }
}
